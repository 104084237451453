import './styles.css';

const nonce = document.querySelector('meta[name="csp-nonce"]').getAttribute('content');

document.addEventListener("DOMContentLoaded", async function() {
    renderLandingPage(nonce);
});

document.addEventListener('contextmenu', function (event) {
    event.preventDefault();
});

const images = [
    './assets/1-min.png',
    './assets/2-min.png',
    './assets/3-min.png',
    './assets/4-min.png',
    './assets/5-min.png',
    './assets/6-min.png',
    './assets/7-min.png',
    './assets/8-min.png',
    './assets/9-min.png',
    './assets/10-min.png',
    './assets/11-min.png',
    './assets/12-min.png',
    './assets/13-min.png',
    './assets/14-min.png',
    './assets/15-min.png',
    './assets/16-min.png',
];

const preloadedImages = [];

function preloadImages() {
    images.forEach((src, index) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            preloadedImages[index] = img;
        };
    });
}
preloadImages();

let currentImageIndex = 0;
let nextImageIndex = getRandomIndex();

function getRandomIndex() {
    let randomIndex;
    do {
        randomIndex = Math.floor(Math.random() * images.length);
    } while (randomIndex === currentImageIndex);
    return randomIndex;
}

function changeBackgroundImage() {
    const backgroundContainer = document.querySelector('.background-container');
    const fadingContainer = document.querySelector('.fading-container');
    const nextImage = preloadedImages[nextImageIndex];

    if (nextImage) {
        fadingContainer.style.backgroundImage = `url(${nextImage.src})`;
        fadingContainer.classList.add('fade-in');

        requestAnimationFrame(() => {
            setTimeout(() => {
                backgroundContainer.style.backgroundImage = `url(${nextImage.src})`;
                fadingContainer.classList.remove('fade-in');
                currentImageIndex = nextImageIndex;
                nextImageIndex = getRandomIndex();
            }, 2000);
        });
    }
}

function renderLandingPage(nonce) {
    
    document.body.innerHTML = '';
    document.body.style.backgroundImage = '';

    const style = document.createElement('style');
    style.setAttribute('nonce', nonce);
    style.textContent = "body { overflow-y: auto; height: 100%; }";
    document.head.appendChild(style);

    const landingContainer = document.createElement('div');
    landingContainer.setAttribute('nonce', nonce);
    landingContainer.classList.add('landing-container');

    const blurLayer = document.createElement('div');
    blurLayer.setAttribute('nonce', nonce);
    blurLayer.classList.add('blur-overlay');

    const mainContent = document.createElement('div');
    mainContent.setAttribute('nonce', nonce);
    mainContent.classList.add('main-content');

    const siteName = document.createElement('h1');
    siteName.setAttribute('nonce', nonce);
    siteName.classList.add('landing-site-name');
    siteName.textContent = 'chorogram';
    siteName.style.fontWeight = 'bold';

    const headline = document.createElement('h2');
    headline.setAttribute('nonce', nonce);
    headline.classList.add('landing-headline');
    headline.textContent = 'Accelerating mycelium materials research together.';

    const mailchimpForm = document.createElement('div');
    mailchimpForm.setAttribute('nonce', nonce);
    mailchimpForm.classList.add('mailchimp-form');
    mailchimpForm.innerHTML = `<div id="mc_embed_shell">
        <link href="//cd n-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
        <div id="mc_embed_signup">
            <form action="https://thr34d5.us10.list-manage.com/subscribe/post?u=10af0120629d97fd32eabfb83&amp;id=d516277cb7&amp;f_id=006346e4f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                <div id="mc_embed_signup_scroll"><h2>Join the waiting list?</h2>
                <p>Be the first to take a leap in 2025 🌱</p>
                    <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div><div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div><div class="mc-field-group"><label for="mce-LNAME">Last Name <span class="asterisk">*</span></label><input type="text" name="LNAME" class="required text" id="mce-LNAME" required="" value=""></div>
                <div id="mce-responses" class="clear foot">
                    <div class="response" id="mce-error-response" style="display: none;"></div>
                    <div class="response" id="mce-success-response" style="display: none;"></div>
                </div>
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
                /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                <input type="text" name="b_10af0120629d97fd32eabfb83_d516277cb7" tabindex="-1" value="">
            </div>
                <div class="optionalParent">
                    <div class="clear foot">
                        <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Join!">
                    </div>
                </div>
            </div>
        </form>
        </div>
        <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='COMPANY';ftypes[6]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>
        `;

    const salesSection = document.createElement('div');
    salesSection.setAttribute('nonce', nonce);
    salesSection.classList.add('sales-section');

    const salesPoints = [
        { 
            title: 'Explore', 
            text: `Bring mycelium materials to life through vivid visualizations.<br>Dive into cutting-edge advancements.<br>Craft captivating visuals.<br>All fueled by pioneering research methods.`, 
            img: 'assets/image1.png' },
        { 
            title: 'Design', 
            text: `Gain insights from global experts.<br>Create innovative, sustainable mycelium materials with automated assistance.<br>Tap into and enhance collective intelligence.<br>Assess the environmental impact of your designs.`, 
            img: 'assets/image2.png' },
        { 
            title: 'Connect', 
            text: `Share your journey and build your reputation.<br>Access high-quality mycelium materials.<br>Connect with incredible designers and researchers.<br>Develop bioregional networks.`, 
            img: 'assets/image3.png' },
    ];

    salesPoints.forEach((point, index) => {
        const salesPoint = document.createElement('div');
        salesPoint.classList.add('sales-point');
        salesPoint.classList.add(index % 2 === 0 ? 'left' : 'right');

        const image = document.createElement('img');
        image.src = point.img;
        image.alt = point.title;

        const content = document.createElement('div');
        content.classList.add('text-container');
        const title = document.createElement('h3');
        title.textContent = point.title;
        const description = document.createElement('p');
        description.innerHTML = point.text;

        content.appendChild(title);
        content.appendChild(description);

        salesPoint.appendChild(image);
        salesPoint.appendChild(content);
        salesSection.appendChild(salesPoint);
    });

    const footer = document.createElement('footer');
    footer.setAttribute('nonce', nonce);
    footer.classList.add('footer');

    const socialLinks = document.createElement('div');
    socialLinks.classList.add('social-links');
    socialLinks.innerHTML = `
        <a href="mailto:arig@kglakademi.dk" target="_blank"><i class="fas fa-envelope"></i></a>
    `;

    //      <a href="https://twitter.com" target="_blank"><i class="fab fa-discord"></i></a>
    //     <a href="https://linkedin.com" target="_blank"><i class="fab fa-linkedin-in"></i></a>
    //     <a href="https://instagram.com" target="_blank"><i class="fab fa-instagram"></i></a>

    const aboutSection = document.createElement('div');
    aboutSection.classList.add('about-section');

    const aboutText = document.createElement('p');
    aboutText.innerHTML = "A project hosted by the Chair for Biohybrid Architecture, Royal Danish Academy. © 2024 Chorogram. All rights reserved.";
    aboutText.classList.add('about-text');
    aboutText.classList.toggle('visible');

    const logo = document.createElement('img');
    logo.classList.add('landing-logo');
    logo.setAttribute('src', 'assets/Logo dark grey.png');
    logo.setAttribute('alt', 'Chorogram logo');

    mainContent.appendChild(logo);
    mainContent.appendChild(siteName);
    mainContent.appendChild(headline);
    aboutSection.appendChild(aboutText);
    footer.appendChild(socialLinks);
    footer.appendChild(aboutSection);

    const backgroundContainer = document.createElement('div');
    backgroundContainer.classList.add('background-container');
    const fadingContainer = document.createElement('div');
    fadingContainer.classList.add('fading-container');
    
    landingContainer.appendChild(backgroundContainer);
    landingContainer.appendChild(fadingContainer);
    landingContainer.appendChild(blurLayer);
    landingContainer.appendChild(mainContent);
    landingContainer.appendChild(salesSection);
    landingContainer.appendChild(mailchimpForm);
    landingContainer.appendChild(footer);
    document.body.appendChild(landingContainer);

    setInterval(changeBackgroundImage, 5000);
    changeBackgroundImage();
    
    const salesPointsTemp = document.querySelectorAll('.sales-point');
    
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target);
            }
        });
    });

    salesPointsTemp.forEach(point => observer.observe(point));
}

export { renderLandingPage };